<template>
  <van-dialog
    v-model="visible"
    title="扫码支付"
    confirm-button-text="取消"
    @show="checkPayStatus"
    @close="closeDialog"
  >
    <div class="box">
      <div class="qr-container">
        <img :src="src" />
      </div>
      <div class="tips">
        如果想使用本机扫码,可以将图片保存到相册后,使用微信扫一扫识别相册图片进行支付
      </div>
    </div>
  </van-dialog>
</template>

<script>
export default {
  name: "WxPay",
  props: {
    visible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    oid: {
      type: String,
    },
    src: {
      type: String,
    },
  },
  data() {
    return {
      timer: null,
    };
  },
  watch: {
    url(url) {
      this.$store.commit("setLoading", !url);
    },
    visible(visible) {
      clearTimeout(this.timer);
      if (visible) this.checkPayStatus();
    },
  },
  methods: {
    setCheckRequest() {
      let oid = this.oid;
      this.$http.post("check_pay_status", { oid, silent: true }).then((res) => {
        if (res.code === 1) {
          clearTimeout(this.timer);
          this.$store.dispatch("getInit");
          this.payed = true;
          setTimeout(() => {
            this.closeDialog();
          }, 1500);
        }
      });
    },
    checkPayStatus() {
      this.timer = setInterval(() => {
        this.setCheckRequest();
      }, 1000);
    },
    closeDialog() {
      clearTimeout(this.timer);
      $emit("update:visible", false);
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  deactivated() {
    clearTimeout(this.timer);
  },
};
</script>
<style scoped>
.box {
  width: 100%;
  background-color: #ffffff;
  text-align: center;
}
.box .tips {
  padding: 10px;
  font-size: 14px;
  line-height: 28px;
}
.qr-container {
  display: inline-block;
  border: 5px solid #2f9833;
  margin: 15px 10px;
}
</style>
